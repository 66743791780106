// 批量导入xls配置
export const sheetKeysMap = {
  编号: 'song_code',
  歌曲: 'song_name',
  副标题: 'sub_name',
  作词者: 'lyricists',
  作曲者: 'composers',
  类型: 'category_names',
  背景资料: 'intros'
}
