<template>
  <a-card title="批量导入谱例歌曲">
    <template #extra>
      <a @click="history.go(-1)">返回</a>
    </template>
    <a-row>
      <a-col span="6">
        <a-upload directory :fileList="allFileList" :beforeUpload="beforeUpload" :onRemove="handleRemove">
          <a-button :disabled="!pageState.configSelected">
            选择文件夹
          </a-button>
        </a-upload>
        <div v-if="!pageState.configSelected" style="color: red">请先选择配置文件</div>
      </a-col>
      <a-col span="6">
        <a-button :disabled="!pageState.canReset" @click="handleReset">
          重置
        </a-button>
        <a-button type="primary" style="margin-bottom: 8px; margin-left: 8px" :loading="pageState.uploading"
                  :disabled="allFileList.length === 0 || pageState.uploading || pageState.loading" @click="handleUpload">
          开始上传
        </a-button>
        <a-button type="primary" style="margin-left: 8px" :loading="pageState.loading" :disabled="!pageState.finish"
                  @click="handleBatch">
          创建
        </a-button>
        <div>
          <a-upload :fileList="[]" :accept="acceptXlsx" :beforeUpload="selectConfigFile">
            <a-button style="margin-top: 8px">
              <PlusOutlined /> 配置文件
            </a-button>
          </a-upload>
        </div>

      </a-col>
      <a-col span="12">
        <a-card title="批量上传说明">
          <section>
            <h3>上传的文件夹下需要有：</h3>
            以歌曲编码命名的文件夹
          </section>
          <section>
            <h3>确保文件夹下包含以下文件：</h3>
            一个或多个.mp3文件
          </section>
        </a-card>
      </a-col>
    </a-row>
  </a-card>

  <a-modal v-if="pageState.errorVisible" destroyOnClose title="文件错误" :footer="null" :width="1000" :maskClosable="false"
           :visible="pageState.errorVisible" style="top: 32px" :body-style="{ lineHeight: 2 }" :onCancel="handleErrorsCancel">
    <div>
      <div>
        (提示：建议根据上述结果确认不可上传的文件，按照指定的目录结构整理欣赏歌曲文件，确认完成后再关闭本弹窗)
      </div>
      <div style="color: red">
        以下文件不可上传，请注意文件所在目录层级：
      </div>
      <div style="margin-left: 1em; line-height: 1.5">
        <div v-for="file in errorFiles" :key="file.webkitRelativePath">{{ file.webkitRelativePath }}</div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { ref, reactive } from 'vue'
import { message, Modal } from 'ant-design-vue'
import { importAcceptDir, importDirDesc, sheetKeysMap, songSheetType } from '@/utils/dataMap/appreciation'
import SparkMD5 from 'spark-md5'
import { uploadFileToOss } from '@/utils/oss'
import { createAppreciation } from '@/services/appreciation'
import { PlusOutlined } from '@ant-design/icons-vue'
import { xlsxImport, acceptXlsx } from '@/utils/fileUpload/xlsxImport'
import { getVarType, concatWithoutSame } from '@/utils/common'

export default {
  name: 'Import',
  components: {
    PlusOutlined
  },
  setup () {
    const configData = ref(null)
    const CONFIG_URL = 'https://prod-ecoach-file.oss-cn-shenzhen.aliyuncs.com/config-music.xlsx'
    const browsers = ['Chrome', 'Firefox', 'Edge', 'QQ浏览器']
    const ignoreFiles = ['.DS_Store']
    const SONG_NAME_INDEX = 2 // 歌曲识别码的位置
    const allFileList = ref([])
    const errorFiles = ref([])
    const tempFileList = ref([])
    const resources = ref([])
    const paraExcel = ref([])
    const pageState = reactive({
      errorVisible: false,
      canReset: true,
      uploading: false,
      md5: [],
      finish: false,
      configSelected: false
    })

    const showRemoveConfirm = file => {
      Modal.confirm({
        title: '确定删除',
        content: <div>当前文件已上传：{file.percent}%，确认删除？</div>,
        onOk () {
          allFileList.value = allFileList.value.filter(item => item.uid !== file.uid)
        }
      })
    }

    const handleRemove = file => {
      const { status } = file
      switch (status) {
      case 'uploading':
        showRemoveFileConfirm(file, '文件正在上传，确定删除？')
        break
      case 'done':
        showRemoveFileConfirm(file, '文件已上传成功，确定删除？')
        break
      default:
        allFileList.value = allFileList.value.filter(item => item.uid !== file.uid)
        break
      }
    }

    // 删除文件时的提示
    const showRemoveFileConfirm = (file, title) => {
      confirm({
        title,
        content: `文件：${file.webkitRelativePath}`,
        okText: '确定',
        cancelText: '取消',
        width: 600,
        onOk: () => {
          allFileList.value = allFileList.value.filter(item => item.uid !== file.uid)
        }
      })
    }

    const beforeUpload = (file, fileList) => {
      // 检查当文件
      if (!fileCheck(file)) {
        errorFiles.value.push(file) // 不可上传的文件
      } else {
        tempFileList.value.push(file) // 可上传文件
      }

      // 到最后一个文件的时候，再做一些处理
      if (fileList.indexOf(file) === fileList.length - 1) {
        const concatKey = 'webkitRelativePath'
        //! 以 allFileList 为准，因此 allFileList 在前面
        const result = concatWithoutSame(concatKey, allFileList.value, tempFileList.value)
        tempFileList.value = [] //* 置空临时文件列表
        allFileList.value = result

        // 存在不可上传的文件时，展示 error modal
        if (errorFiles.value.length > 0) {
          pageState.errorVisible = true
        }
      }
      return false
    }

    const handleErrorsCancel = () => {
      pageState.errorVisible = false
      errorFiles.value = []
    }

    const handleReset = () => {
      allFileList.value = []

      selectConfigFile.value = null
      pageState.configSelected = false
      message.success('数据重置成功！')
    }

    const complexUpload = async index => {
      await uploadWithMd5(index)

      if (index + 1 >= allFileList.value.length) {
        pageState.finish = true
        pageState.uploading = false
        message.success('文件已上传，请点击“创建”按钮保存')
        return
      }

      return complexUpload(index + 1)
    }

    const handleUpload = () => {
      pageState.canReset = false
      pageState.uploading = true
      complexUpload(0)
    }

    const uploadWithMd5 = async index => {
      const file = allFileList.value[index]

      // 跳过已经上传的文件
      if (file.status === 'done') return

      const blobSlice =
        File.prototype.slice ||
        File.prototype.mozSlice ||
        File.prototype.webkitSlice
      const chunkSize = 2097152 // Read in chunks of 2MB
      const chunks = Math.ceil(file.size / chunkSize)
      const spark = new SparkMD5.ArrayBuffer()
      const reader = new FileReader()
      let currentChunk = 0

      function loadNext () {
        const start = currentChunk * chunkSize

        const end =
          start + chunkSize >= file.size ? file.size : start + chunkSize

        reader.readAsArrayBuffer(blobSlice.call(file, start, end))
      }

      reader.onload = e => {
        spark.append(e.target.result) // Append array buffer
        currentChunk += 1
        if (currentChunk < chunks) {
          loadNext()
        } else {
          pageState.md5.push([file.webkitRelativePath, spark.end()])
        }
      }

      loadNext()

      try {
        const data = await uploadFileToOss(file, true)

        file.percent = 100
        file.status = 'done'
        file.url = data.url
        file.md5 = data.md5

        allFileList.value = allFileList.value.slice()

        // 文件上传结束，下面将上传后的文件处理到 resources 数组里
        const mp3_file_md5 = pageState.md5.find(
          item => item[0] === file.webkitRelativePath
        )[1]
        // 以文件所在的文件夹作为 dir 标记，不要携带文件名
        const splits = file.webkitRelativePath.split('/')
        splits.pop() // 去除文件名
        const mp3File = {
          dir: splits.join('/'),
          filename: file.name, // 记下文件名，后面会通过这个来对 mp3 排序
          mp3_file_url: file.url,
          mp3_file_md5,
          para_excel_url: undefined
        }

        const song_name = file.webkitRelativePath.split('/')[SONG_NAME_INDEX]
        const resourceIndex = resources.value.findIndex(
          item => String(item.song_name) === String(song_name)
        )
        if (resourceIndex > -1) {
          // 该歌曲已存在
          resources.value[resourceIndex].mp3_file_urls.push(mp3File)
        } else {
          // 该歌曲不存在
          const item = {
            song_name,
            song_type: 1,
            mp3_file_urls: [mp3File],
            picture_urls: []
          }
          resources.value.push(item)
        }
      } catch (e) {
        message.error('上传出错')
        console.error(e)
      }
    }

    // 整理上传完成的文件
    const prepareFiles = fileList => {
      fileList.forEach(file => {
        const filePath = file.webkitRelativePath.toLowerCase()
        const songCode = file.song_code
        const configItem = configData.value[songCode]
        if (!configItem.resources) {
          configItem.resources = []
        }
        const rename = file.name.substring(0, file.name.lastIndexOf('.'))
        configItem.resources.push({
          file_name: rename,
          file_md5: file.md5,
          file_size: file.size,
          file_url: file.url
        })
        if (!configItem) {
          throw new Error('数据处理错误，请检查配置文件')
        }
        if (filePath.includes('mp3')) {
          // 伴唱歌曲文件
          configItem.acc_file_md5 = file.md5
          configItem.acc_file_url = file.url
          configItem.acc_file_size = file.size
        }
      })
      return Object.values(configData.value)
    }

    const handleBatch = async () => {
      pageState.loading = true
      let resources

      try {
        resources = prepareFiles(allFileList.value)

        if (!resources) return
        for (const k in configData.value) {
          const item = configData.value[k]
          console.log(item)
          await createAppreciation(item)
        }
        pageState.loading = false
        message.success('批量导入成功')
        history.go(-1)
      } catch (error) {
        message.error(error.message)
        console.error(error)
      }
    }

    // 从文件的相对路径中获取歌曲识别码
    const getSongCode = filePath => {
      let songCode
      const pathSplit = filePath.split('/')
      const filename = pathSplit.pop()
      const fileDir = pathSplit[1]?.toLowerCase() // importAcceptDir 中的一个值

      // 原伴唱文件
      if (fileDir === 'acc' || fileDir === 'ori') [songCode] = filename.split('-')

      // 曲谱文件
      if (fileDir === 'evxml' || fileDir === 'musicxml' || fileDir === 'evms') {
        [songCode] = filename.split('.')
      }

      // 歌曲图片
      if (fileDir === 'image') [, , songCode] = pathSplit

      return songCode
    }

    // 检查文件路径的合法性，true 合法, false 非法
    const fileCheck = file => {
      if (!configData.value) return false

      const filePath = file.webkitRelativePath
      const pathSplit = filePath.split('/')
      const songCode = pathSplit[1].toLowerCase() // importAcceptDir 中的一个值
      // 从文件的相对路径中获取歌曲识别码
      // 没有读取到 songCode, 不能上传
      if (songCode === undefined) return false
      const current = configData.value[songCode]
      if (current === undefined) return false

      const suffix = file.name.slice(-4)
      if (suffix === '.mp3') {
        // 没有读到配置文件的信息，不能上传
        file.song_code = songCode
        return true
      } else {
        return false
      }
    }

    // 整理配置文件的内容
    const prepareConfigData = sheets => {
      // 只读取第 1 个 sheet 中的内容
      const sheetRawData = Object.values(sheets)[0]
      const sheetData = {}
      sheetRawData.forEach(sheetItem => {
        const item = {}
        const keys = Object.keys(sheetKeysMap)
        keys.forEach(key => {
          sheetItem[key] = sheetItem[key] ?? ''
          if (['lyricists', 'composers', 'category_names'].includes(sheetKeysMap[key])) {
            item[sheetKeysMap[key]] = sheetItem[key].split('/')
          } else if (sheetKeysMap[key] === 'intros') {
            item[sheetKeysMap[key]] = `<p>${sheetItem[key]}</p>`
          } else {
            item[sheetKeysMap[key]] = sheetItem[key]
          }
        })
        const { ...value } = item

        // 排除空白数据的干扰
        if (!value.song_code) return
        if (getVarType(value.song_code) === 'string') {
          const code = value.song_code.replaceAll(' ', '')
          if (code === '') return
        }
        sheetData[value.song_code] = value
      })

      configData.value = sheetData
      message.success('配置文件读取完成，请选择需要上传的文件夹')
    }

    // 选择配置文件
    const selectConfigFile = file => {
      // 没有文件时，表示清空配置文件信息
      if (!file) {
        configData.value = null
        return
      }
      const getDataFromConfigFile = () => {
        xlsxImport(file)
          .then(prepareConfigData)
          .catch(console.error)
        pageState.configSelected = true
      }

      if (configData.value !== null) {
        Modal.confirm({
          centered: true,
          maskClosable: false,
          title: '注意',
          content:
            '当前已存在配置文件数据，重新选择将覆盖原配置文件的内容，是否确认？',
          okText: '确认覆盖',
          onOk: () => getDataFromConfigFile()
        })
        return
      }

      getDataFromConfigFile()
    }

    return {
      songSheetType,
      acceptXlsx,
      importAcceptDir,
      importDirDesc,
      errorFiles,
      pageState,
      CONFIG_URL,
      browsers,
      history,
      allFileList,
      handleRemove,
      beforeUpload,
      handleErrorsCancel,
      handleReset,
      handleUpload,
      handleBatch,
      selectConfigFile
    }
  }
}
</script>

<style lang="less" scoped>
.outerLevel {
  padding-left: 2em;
}

.innerLevel {
  padding-left: 1.5em
}
</style>
